* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  letter-spacing: -0.3px;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.5s alternate;
}

.fade-out {
  animation: fadeOut 0.5s alternate;
}

/* Specific for WebKit browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Specific for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  background: #F3F1F5;
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

.chat-modal {
  /*top: 1.5rem !important;*/
}

.chat-modal .close-modal-icon {
  position: absolute;
  top: 1.75rem;
  right: 1.75rem;
  z-index: 10;
  background: #FFF;
}

.departureStep-content-visible {
  overflow-y: visible !important;
}

.departureStep-content-scroll {
  overflow-y: scroll !important;
}

#pickUp {
  cursor: initial;
}

.react-international-phone-input-container {
  color: #3A3875 !important;
  border-radius: 0.25rem !important;
  /*padding: 0.75rem 1rem 0.75rem 3rem !important;*/
  height: 2.813rem !important;
  font-size: 0.875rem !important;
  line-height: 1.313rem !important;
  width: 100% !important;
}
.react-international-phone-country-selector-button {
  height: 2.813rem !important;
}

.react-international-phone-input {
  width: 100% !important;
  height: 2.813rem !important;
  color: #3A3875 !important;
  font-size: 0.875rem !important;
  line-height: 1.313rem !important;
}

.react-international-phone-input:disabled {
  background: #f6f6fb !important;
  border: none !important;
}

.react-international-phone-country-selector-button:disabled {
  background: #f6f6fb !important;
  border: none !important;
}

@media(max-width: 767px) {
  .MuiDateCalendar-root {
    width: 300px !important;
  }
}